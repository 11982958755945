import React from 'react'

import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
interface SumsubModalProps {
  accessToken: string | null
  closeModal: () => void
  isModalOpen: boolean
}
import SumsubWebSdk from '@sumsub/websdk-react'
const SumsubModal = ({ accessToken, closeModal, isModalOpen }: SumsubModalProps) => {
  return (
    <Flex>
      <Modal size="lg" isOpen={isModalOpen} onClose={() => closeModal()}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton top={0} right={0} color="white" />
          <ModalBody backgroundColor="black" p={8}>
            {accessToken && (
              <SumsubWebSdk
                accessToken={accessToken}
                onError={(data: any) => {
                  console.log('daa', data)
                }}
                onMessage={(data: any) => {
                  console.log('message', data)
                }}
                expirationHandler={() => {
                  console.log('expired')
                }}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
export { SumsubModal }
