import './App.css'
import { ChakraProvider } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'

import './fonts/font.css'
import { Header } from './components/Header'
import { Home } from './pages/Home'
function App() {
  return (
    <ChakraProvider>
      <Box bg="black" height="100vh">
        <Header />
        <Home />
      </Box>
    </ChakraProvider>
  )
}

export default App
